import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './mission.scss';

const nextIcon = require('../images/icon-arrow-next-white@3x.png');

const isaacImg = require('../images/member/isaac.jpg');
const sangImg = require('../images/member/sang.jpg');
const phatImg = require('../images/member/phat.jpg');
const kaytlynImg = require('../images/member/kaytlyn.jpg');
const phuImg = require('../images/member/phu.jpg');
const trangImg = require('../images/member/trang.jpg');
const thuImg = require('../images/member/thu.jpg');
const khoaImg = require('../images/member/khoa.jpg');
const dangImg = require('../images/member/dang.jpg');
const phuongImg = require('../images/member/phuong.jpg');
const tanImg = require('../images/member/tan.jpg');
const nhanImg = require('../images/member/nhan.jpg');
const thangImg = require('../images/member/thang.jpg');
const hoangImg = require('../images/member/hoang.jpg');
const minhImg = require('../images/member/minh.jpg');
const danhImg = require('../images/member/danh.jpg');
const luanImg = require('../images/member/luan.jpg');

const Mission = () => {
  const [startValue, focusValue] = useState(false);
  const [startTeam, focusTeam] = useState(false);

  const fade = (index) => useSpring({
    opacity: 1, delay: index * 200, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(60px)' }, config: { duration: 300 },
  });

  const onFocusValue = (isVisible) => {
    if (!startValue) focusValue(isVisible);
  };

  const onFocusTeam = (isVisible) => {
    if (!startTeam) focusTeam(isVisible);
  };
  const fadeValue = (type, index) => (type ? useSpring({
    opacity: 1, transform: 'translateY(0)', delay: index * 100, config: { duration: 200 },
  }) : useSpring({ opacity: 0, transform: 'translateY(50px)' }));

  return (
    <Layout>
      <SEO title="Mission" />
      <div className="Mission">
        <div className="Mission__One">
          <animated.div style={fade(0)} className="Mission__One__Title">
            Move ideas forward
          </animated.div>
          <animated.div style={fade(1)} className="Mission__One__Des">
            Our vision is to connect changemakers
            <br />
            inspired to solve a problem with the process,
            <br />
            people, and resources they need.
          </animated.div>
        </div>
        <div className="Mission__Two">
          <div className="Mission__Two__Block" />
          <div className="Mission__Two__Info">
            <animated.div style={fade(5)} className="Mission__Two__Info__Title">Our story</animated.div>
            <animated.div style={fade(6)} className="Mission__Two__Info__Des">
              Our founding team came together after experiencing
              similar, painful experiences of building too much product
              before launching and getting real-world customer
              feedback.
              <br />
              <br />
              Since day 1, Launch Deck’s mission has been to help move
              ideas forward, by testing ideas as quick and efficiently as
              possible. From working with hundreds of founders, and
              dozens of new product launches, we’ve created a holistic
              startup process that supports founders on their journey.
              <br />
              <br />
              We are in the trenches building product, doing market and
              customer research. We experience the best moments of
              product launches and customer insights… and we’re there to
              lift people back up after the the lows of failed experiments.
            </animated.div>
          </div>
        </div>
        <div className="Mission__Three">
          <div className="Mission__Three__Title" />
          <div className="Mission__Three__Title__Mobile">Our values</div>
          <div className="Mission__Three__List">
            <VisibilitySensor onChange={onFocusValue}>
              <div className="Mission__Three__Item">
                <animated.div style={fadeValue(startValue, 0)} className="Mission__Three__Item__Title">Entrepreneurial</animated.div>
                <animated.div style={fadeValue(startValue, 1)} className="Mission__Three__Item__Des">
                  We are creative with limited

                  resources and always put

                  people first.
                </animated.div>
              </div>
            </VisibilitySensor>
            <div className="Mission__Three__Item">
              <animated.div style={fadeValue(startValue, 2)} className="Mission__Three__Item__Title">Empathy</animated.div>
              <animated.div style={fadeValue(startValue, 3)} className="Mission__Three__Item__Des">
                We’re obsessed with
                <br />
                people’s experiences.
              </animated.div>
            </div>
            <div className="Mission__Three__Item">
              <animated.div style={fadeValue(startValue, 4)} className="Mission__Three__Item__Title">Partner</animated.div>
              <animated.div style={fadeValue(startValue, 5)} className="Mission__Three__Item__Des">
                We support the journey with
                <br />
                guidance and positivity.
              </animated.div>
            </div>
            <div className="Mission__Three__Item">
              <animated.div style={fadeValue(startValue, 6)} className="Mission__Three__Item__Title">Growth</animated.div>
              <animated.div style={fadeValue(startValue, 7)} className="Mission__Three__Item__Des">
                We are always learning and
                <br />
                evolving.
              </animated.div>
            </div>
            <div className="Mission__Three__Item">
              <animated.div style={fadeValue(startValue, 8)} className="Mission__Three__Item__Title">Flexible</animated.div>
              <animated.div style={fadeValue(startValue, 9)} className="Mission__Three__Item__Des">
                We iterate and adapt to new
                <br />
                insights for improvement.
              </animated.div>
            </div>
            <div className="Mission__Three__Item">
              <animated.div style={fadeValue(startValue, 10)} className="Mission__Three__Item__Title">Focus</animated.div>
              <animated.div style={fadeValue(startValue, 11)} className="Mission__Three__Item__Des">
                We are clear, transparent,
                <br />
                and focus on what we can
                <br />
                control.
              </animated.div>
            </div>
          </div>
        </div>
        <div className="Mission__Four">
          <VisibilitySensor onChange={onFocusTeam}>
            <animated.div style={fadeValue(startTeam, 1)} className="Mission__Four__Title">Meet the Team</animated.div>
          </VisibilitySensor>
          <animated.div style={fadeValue(startTeam, 2)} className="Mission__Four__Des">
            We are founders, designers, and developers who help startups transform ideas
            <br />
            into products. We work remotely with clients around the world with our team
            <br />
            located in the Midwest, United States and Ho Chi Minh, Vietnam.
          </animated.div>
          <div className="Mission__Four__People">
            <animated.img src={isaacImg} style={fadeValue(startTeam, 3)} className="Mission__Four__People__Item" />
            <animated.img src={kaytlynImg} style={fadeValue(startTeam, 4)} className="Mission__Four__People__Item" />
            <animated.img src={phatImg} style={fadeValue(startTeam, 5)} className="Mission__Four__People__Item" />
            <animated.img src={khoaImg} style={fadeValue(startTeam, 6)} className="Mission__Four__People__Item" />
            <animated.img src={danhImg} style={fadeValue(startTeam, 7)} className="Mission__Four__People__Item" />
            <animated.img src={thuImg} style={fadeValue(startTeam, 8)} className="Mission__Four__People__Item" />
            <animated.img src={sangImg} style={fadeValue(startTeam, 9)} className="Mission__Four__People__Item" />
            <animated.img src={trangImg} style={fadeValue(startTeam, 10)} className="Mission__Four__People__Item" />
            <animated.img src={thangImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={phuongImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={luanImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={hoangImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={phuImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={nhanImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={dangImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={tanImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <animated.img src={minhImg} style={fadeValue(startTeam, 11)} className="Mission__Four__People__Item" />
            <Link to="/contact-us/" className="Mission__Four__People__Btn">
              <div className="Mission__Four__People__Btn__Text">
                Want to be a
                <br />
                next one?
              </div>
              <img src={nextIcon} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mission;
